.carousel-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  height: auto; /* Full viewport height */
  width: 100vw;
}

.carousel {
  display: flex;
  width: 90%;
  height: 80%;
  padding: 5%;
}

.FAQs {
  margin: 50px 0px;
  padding: 50px;
  background: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.FAQs h3 {
  font-size: 25px;
  font-weight: bold;
}
.FAQs h5 {
  font-size: 16px;
}
.FAQs h6 {
  font-size: 15px;
  color: rgb(238, 79, 16);
  /* color: #ff7f23; */
}
.FAQs .categoryCards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.FAQs .categoryCards .categoryCard {
  width: 400px;
  height: 450px;
  background: #fff;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  overflow: hidden;
}
.FAQs .categoryCards .categoryCard .categoryCardImg {
  width: 100%;
  height: 200px;
}
.FAQs .categoryCards .categoryCard .categoryCardImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.FAQs .categoryCards .categoryCard .categoryCardText {
  padding: 20px;
  height: 100%;
  position: relative;
}
.FAQs .categoryCards .categoryCard .categoryCardText h4 {
  font-size: 20px;
  font-weight: bold;
}
.FAQs .categoryCards .categoryCard .categoryCardText ul li {
  list-style-type: "✔️";
  font-size: 16px;
  padding-left: 10px;
  color: #555;
}
.FAQs .categoryCards .categoryCard .categoryCardText a {
  font-size: 14px;
  color: #ff7f23;
  text-decoration: underline;
}
.featured {
  width: 100vw;
  height: 600px;
  background: url("../utils/featured.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.or-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  width: 70%; /* Set the container width to 70% */
  margin: 20px auto; /* Center the entire OR section */
}

.line {
  flex: 1;
  border-top: 1.5px solid #ccc;
  margin: 0 10px;
}

.or-text {
  font-size: 1rem;
  color: #555;
}

/* .center-button {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.btn-primary {
  background-color: #3675b9; 
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0a396d;
} */
.text-option {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px; /* Space between text and buttons */
  color: #333; /* Optional color */
}

.center-button {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px; /* Add space between buttons */
}

.bttn-primary {
  background-color: #3675b9;
  color: white;
  border: none;
  padding: 20px 40px;
  height: 80px; /* Set specific height */
  border-radius: 5px;
  cursor: pointer;
}

.bttn-primary:hover {
  background-color: #0a396d;
  /* background-color: #2f5c90; */
}

.center-button {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  gap: 16px; /* Add spacing between buttons */
  margin: 20px 0; /* Add vertical margin */
}

.bttn-primary {
  width: 100%; /* Make buttons take full width */
  max-width: 300px; /* Optional: Set a max width */
  text-align: center;
}

@media (min-width: 768px) {
  .center-button {
    flex-direction: row; /* Optional: Stack horizontally on larger screens */
    justify-content: center;
  }

  .bttn-primary {
    width: auto; /* Revert to auto width on larger screens */
  }
}

/* Add margins to the container for mobile */
@media (max-width: 768px) {
  .center-button {
    margin-left: 4rem; /* Margin on the left */
    margin-right: 4rem; /* Margin on the right */
    gap: 10px; /* Reduce space between buttons if necessary */
  }
}
